<template>
    <section class="login">
        <form
            id="login-form" 
            @submit="handleLogin">

            <input 
                type="password" 
                v-on:keyup.13="handleLogin" 
                v-model="loginInput"
                placeholder="...Alohomora?" />

            <button 
                type="submit"
                aria-label="Enter website">

                <svg aria-hidden="true" style="height:1em;" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M216.464 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887L209.393 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L233.434 36.465c-4.686-4.687-12.284-4.687-16.97 0z" class=""></path></svg>        
            </button>

            <div v-if="showError" class="login-error">
                Nope, sorry. Try again!
            </div>
        </form>
    </section>
</template>

<script>
import { isLoggedIn, tryLogIn } from '@/authHandler.js';
import { defaultPage } from '@/App.vue';

export default {
    name: "Login",
    data() {
        return {
            loginInput: null,
            showError: false
        }
    },
    mounted() {
        if(isLoggedIn()) {
            this.$router.push(defaultPage);
        }
    },
    methods: {
        handleLogin(event) {
            event.preventDefault();

            if(!tryLogIn(this.loginInput)) {
                this.showError = true;
                return;
            }

            this.showError = false;

            this.$router.push(defaultPage);
        }
    }
}
</script>

<style scoped>
.login
{
    min-height:100vh;
    width:100%;

    background:url(../assets/img/bg-ginkgo.jpeg);
    background-size:cover;
    background-position: center center;

    display:flex;
    flex-direction:column;
    align-items: center;
}

#login-form
{
    position:relative;
    top:15vh;
    display:flex;

    width:100%;
    max-width:22rem;
    padding:1rem;
}

#login-form input,
#login-form button
{
    -webkit-appearance: none;
    appearance: none;
    opacity: 0.85;

    display:flex;

    height:3rem;
    border:none;
    border-radius:0;

    font-family: inherit;
}
#login-form input
{
    font-size:0.875rem;

    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;

    background:var(--sage-green);
    color:var(--minty-white);
    
    padding:0 1rem;
    flex:1 0 auto;
}
#login-form input::placeholder
{
    color:var(--minty-white);
}
#login-form button
{
    font-size:0.875rem;

    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;

    background:var(--minty-white);
    padding:0 1rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;

    font-family: 'Fredericka the Great', serif;
}
#login-form button:hover
{
    opacity:0.7;
}
.login-error
{
    font-size:0.75rem;
    color:#FFFFFF;
    position:absolute;
    bottom:-1rem;
}
</style>