import Vue from 'vue';
import VueRouter from 'vue-router';

import Login from '@/pages/Login';
// import TheBasics from '@/pages/TheBasics';
import TheDay from '@/pages/TheDay';
import TheStory from '@/pages/TheStory';
import ThePeople from '@/pages/ThePeople';
import TheLocation from '@/pages/TheLocation';
import The404 from '@/pages/The404';
import TheGallery from '@/pages/TheGallery';

import { isLoggedIn } from '@/authHandler.js';
import { defaultPage } from '@/App.vue';

Vue.use(VueRouter);

const router = new VueRouter({
    routes: [

        /**
         * Redirect root path to /day.
         */
        {
            path: "/",
            redirect: defaultPage
        },

        /**
         * Login
         */
        {
            name: "Login",
            path: "/login",
            component: Login
        },

        /**
         * The Day
         */
        {
            name: "The Day",
            path: "/day",
            component: TheDay
        },

        /**
         * The Story
         */
        {
            name: "The Story",
            path: "/story",
            component: TheStory
        },

        /**
         * The Area
         */
        {
            name: "The Location",
            path: "/location",
            component: TheLocation
        },

        /**
         * The People
         */
        {
            name: "The People",
            path: "/people",
            component: ThePeople
        },

        // {
        //     name: "The Basics",
        //     path: "/basics",
        //     component: TheBasics
        // },

        {
            name: "The Gallery",
            path: "/gallery",
            component: TheGallery
        },

        /**
         * 404 catchall
         */
        {
            name: "404",
            path: "/*",
            component: The404
        }
    ],
    mode: 'history'
});

router.beforeEach((to, from, next) => {
    if(to.name === "Login") {
        next();
    }
    else {
        if(!isLoggedIn()) {
            next("/login");
        }
        else {
            next();
        }
    }
});

export default router;