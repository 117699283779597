<template>
    <div class="page-content">
        <div class="container">
            <h1 class="page--title">The Story</h1>
            <p class="page--subtitle">How it all started.</p>
            <p class="page--text">Our relationship started with long distance. Because of that, we can count the number of times we saw each other before we finally brought our little family of four together. Here are some snippets from each of those times.</p>
            
            <section 
                class="story-section"
                v-for="storyYear, index in storyYears"
                :key="index">

                <h2 class="year--title">{{ storyYear.year }}</h2>
                <p class="year--text">{{ storyYear.text }}</p>

                <div class="storyboard">
                    <div class="storyboard--column column__1"></div>
                    <div class="storyboard--column column__2"></div>
                    <div class="storyboard--column column__3"></div>

                    <div 
                        class="storyboard--item"
                        v-for="point, index in storyYear.points"
                        :key="index" >

                        <story-point
                            :value="point"></story-point>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Colcade from 'colcade';
import StoryPoint from '@/components/StoryPoint';

export default {
    components: {
        StoryPoint
    },
    computed: {
        storyPointsWithImage() {
            return this.storyPoints.filter(sp => sp.image != null);
        }
    },
    mounted() {
        const storyboards = document.querySelectorAll('.storyboard');
        storyboards.forEach(storyboard => {
            new Colcade(storyboard, {
                columns: '.storyboard--column',
                items: '.storyboard--item'
            });
        });
    },
    data() {
        return {
            storyYears: [
                {
                    year: 2017,
                    text: `The first time we saw each other was actually on a hike up to Schloss Drachenburg. It was later also one of the first things we did as close friends, so it's a very meaningful place to both of us that we're excited to share with all of you! We then had our "first date" in Ireland and then started our long-distance relationship. Alex went back to the US and Jill was in Japan for a few months before we finally reunited in Chicago for New Year's Eve and Jill got to visit Columbus, Alex's hometown.`,
                    points: [
                        {
                            date: `March 2017`,
                            title: `Met in Bonn`,
                            image:require('@/assets/img/story/2017-03-Bonn.jpg')
                        },
                        {
                            date:`July 2017`,
                            title:`First Trip to Dublin`,
                            image:require('@/assets/img/story/2017-07-Ireland.jpg')
                        },
                        {
                            date:`December 2017`,
                            title:`Chicago & Columbus`,
                            image:require('@/assets/img/story/2018-01-Chicago.jpg')
                        }
                    ]
                },
                {
                    year: 2018,
                    text: `Since we had to cross the Atlantic to see each other, we made the most of it and planned some fun vacations in Canada, Romania, and, once Alex moved there, Colorado. Our relationship kept getting stronger and being together during important events like Alex's graduation and holidays was a big part of that.`,
                    points: [
                        {
                            date:`Spring 2018`,
                            title:`Canada & Columbus`,
                            image:require('@/assets/img/story/2018-03-Canada.jpg')
                        },
                        {
                            date:`May 2018`,
                            title:`Alex's Graduation`,
                            image:require('@/assets/img/story/2018-05-Columbus.jpg')
                        },
                        {
                            date:`Summer 2018`,
                            title:`Alex in Bonn & Trip to Romania`,
                            image:require('@/assets/img/story/2018-06-Romania.jpg')
                        },
                        {
                            date:`Autumn 2018`,
                            title:`Jill in Denver`,
                            image:require('@/assets/img/story/2018-10-Colorado.jpg')
                        },
                        {
                            date:`December 2018`,
                            title:`New Years in Frankfurt`,
                            image:require('@/assets/img/story/2018-12-Frankfurt.jpg')
                        },
                    ]
                },
                {
                    year: 2019,
                    text: `Our trips together were sweet and exciting, but short. Our relationship needed to be tested with boring "everyday" stuff! Alex spent the whole summer in Germany and brought August with him - because how else would the cat finally get used to Jill?`,
                    points: [
                        {
                            date:`Spring 2019`,
                            title:`Roadtrip Colorado`,
                            image:require('@/assets/img/story/2019-03-Colorado.jpg')
                        },
                        {
                            date:`May 2019`,
                            title:`North Carolina`,
                            image:require('@/assets/img/story/2019-05-Charlotte.jpg')
                        },
                        {
                            date:`Summer 2019`,
                            title:`Alex in Germany with August`,
                            image:require('@/assets/img/story/2019-06-Germany.jpg')
                        },
                        {
                            date:`Autumn 2019`,
                            title:`Roadtrip California / Nevada`,
                            image:require('@/assets/img/story/2019-10-Sanfran.jpg')
                        },
                        {
                            date:`December 2019`,
                            title:`Alex in Frankfurt`,
                            image:require('@/assets/img/story/2020-01-Frankfurt.jpg')
                        }
                    ]
                },
                {
                    year: 2020,
                    text:`Luckily, in February 2020 Jill flew to Denver– right before the pandemic hit and kept us apart for the longest time yet. After many difficulties and a failed attempt, Alex could finally move to Frankfurt and we put an end to the long-distance! Later that year we moved into a bigger apartment and started building our own little home with our two furry babies.`,
                    points: [
                        {
                            date:`February 2020`,
                            title:`Jill's Birthday in Denver`,
                            image:require('@/assets/img/story/2020-02-Colorado.jpg')
                        },
                        {
                            date:`Summer 2020`,
                            title:`Alex moved to Germany`,
                            image:require('@/assets/img/story/2020-07-Germany.jpg')
                        },
                        {
                            date:`December 2020`,
                            title:`Moved into our first shared apartment`,
                            image:require('@/assets/img/story/2020-12-Apartment.jpg')
                        }
                    ]
                },
                {
                    year: `2021 and Forward`,
                    text: `Life just keeps getting better: We’re still crazy about traveling and our cats – nothing changed there 😉 We also fulfilled two life-long dreams by buying a Mini Clubman and visiting the Seychelles. Since Jill wanted to go there forever – Alex took the opportunity and proposed on top of the Copolia Trail. And managed to surprise Jill with his timing…`,
                    points: [
                        {
                            date:`March 2021`,
                            title:`Bought our first (dream) car together`,
                            image:require('@/assets/img/story/2021-03-Mini.jpg')
                        },
                        {
                            date:`May 2021`,
                            title:`Trip to Switzerland`,
                            image:require('@/assets/img/story/2021-05-Switzerland.jpg')
                        },
                        {
                            date:`September 2021`,
                            title:`Engagement on Mahé`,
                            image:require('@/assets/img/story/2021-09-Mahe.jpg')
                        },
                        {
                            date:`December 2021`,
                            title:`Christmas in Colorado`,
                            image:require('@/assets/img/story/2021-12-Colorado.jpg')
                        },
                        {
                            date:`Forever`,
                            title:`Calcifer and August`,
                            image:require('@/assets/img/story/2022-01-Cats.jpg')
                        }
                    ]
                }
            ]
        }
    }
}
</script>

<style scoped>
.story-section
{
    padding:2rem 0;
}
.year--title
{
    text-align:center;
    font-family: 'Fredericka the Great', serif;
    text-align: center;
    margin: 0 0 0.5rem;
    font-size: 2.5rem;
    font-weight: normal;
}
.year--text
{
    font-size: 0.875rem;
    margin: 0 auto 1.5rem;
    text-align: center;
    width: 100%;
    padding:0 1rem;
    max-width:992px;
}
.storyboard
{
    max-width:30rem;
    margin:0 auto;
    width:100%;
    display: grid;
    grid-template-columns:repeat(1, 1fr);
    gap:1.5rem;
}
.storyboard--column
{
    display:flex;
    flex-direction:column;
    gap:1.5rem;
}
.column__2,
.column__3
{
    display:none;
}
@media screen and (min-width:768px)
{
    .storyboard
    {
        max-width:100%;
        grid-template-columns:repeat(2, 1fr);
    }
    .column__2
    {
        display: flex;
    }
}
@media screen and (min-width:998px)
{
    .storyboard
    {
        grid-template-columns:repeat(3, 1fr);
    }
    .column__3
    {
        display:flex;
    }
}
</style>