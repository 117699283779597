<template>
    <section class="page--section">
        <h2 class="section--title">{{ title  }}</h2>
        <p 
            class="section--text"
            :class="{'text__center': center}"><slot></slot></p>
    </section>
</template>

<script>
export default {
    props: {
        title: {
            required: true,
            type: String
        },
        center: {
            type: Boolean,
            default: false
        }
    }
}
</script>