<template>
    <div
        class="storypoint">

        <img :src="value.image" class="storypoint--image" /> 

        <div class="storypoint--content-front">
            <h2 class="content--title">{{ value.title }}</h2>
            <span class="content--date">{{ value.date }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => {
                return {
                    title: '',
                    date: '',
                    image: null
                }
            }
        }
    }
}
</script>

<style scoped>
.storypoint
{
    width:100%;
    position:relative;
    border-radius:0.75rem;
    overflow:hidden;
    box-shadow: 0.125rem 0.125rem 0.125rem 0 rgba(50, 50, 50, 0.1);
}
.storypoint--image
{
    display:block;
    width:100%;
}
.storypoint--content-front
{
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items:flex-end;
    text-align:right;
    color:#FFF;
    padding:1.5rem;

    background: rgb(0,0,0,0.1);
    background: linear-gradient(135deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 20%, rgba(0,0,0,0.4) 100%);
    text-shadow:0 0 1em #000;
}
.content--title
{
    margin:0;
    padding:0;
    font-size:1.125rem;
    font-family: 'Fredericka the Great', serif;
    font-weight:normal;
}
.content--date
{
    margin:0;
    font-size:0.875rem;
}
</style>