<template>
    <div class="page-content">
        <div class="container">
            <h1 class="page--title">The Location(s)</h1>
            <p class="page--subtitle">Where it happened.</p>
            <p class="page--text">The ceremony took place in Schloss Drachenburg in Königswinter (near Bonn). After that, we headed over to The Fiddlers Irish Pub in Endenich, Bonn to celebrate!</p>

            <div class="locations">
                <section class="location-section">
                    <h2>The Castle</h2>
                    <div class="location--subitem subitem__main">
                        <h3>Schloss Drachenburg</h3>
                        <p><a href="https://www.schloss-drachenburg.de" target="_blank">https://www.schloss-drachenburg.de</a></p>
                        <p>
                            Drachenfelsstraße 118<br/>
                            53639 Königswinter
                        </p>
                    </div>
                    <div class="location--subitem">
                        <h3>Bergbahn</h3>
                        <p>The Bergbahn ("mountain train") offers an easier way to access the castle than walking.</p>
                        <p>It runs every 30 minutes, so please take the 14:00 train to be on time!</p>
                        <p><a href="https://www.drachenfelsbahn.de/index.php/de/fahrplan-preise" target="_blank">More info here.</a></p>
                    </div>
                    <div class="location--subitem">
                        <h3>Parkplatz Oberweingartenweg</h3>
                        <p><a href="https://maps.app.goo.gl/KFgdWLGdnhTMgmvA9" target="_blank">Maps Link</a></p>
                        <p>Closest parking to the castle (10-15 min walk).</p>
                    </div>
                </section>
                
                <section class="location-section">
                    <h2>The Pub</h2>
                    <div class="location--subitem subitem__main">
                        <h3>The Fiddlers Irish Pub</h3>
                        <p><a href="https://www.thefiddlerspubbonn.com" target="_blank">https://www.thefiddlerspubbonn.com</a></p>
                        <p>
                            Frongasse 9<br />
                            53121 Bonn - Endenich
                        </p>
                    </div>
                    <div class="location--subitem">
                        <h3>Parkplatz Endenich</h3>
                        <p><a href="https://maps.app.goo.gl/dUxoCXREw31fv2dk8" target="_blank">Maps Link</a></p>
                    </div>
                </section>
                <section class="location-section">
                    <h2>The Accomodations</h2>
                    <div class="location--subitem subitem__main">
                        <h3>Das Hotel Krone</h3>
                        <p><a href="https://www.daskrone.de" target="_blank">Website</a></p>
                        <p>
                            Hauptstrasse 374<br />
                            Königswinter 53639
                        </p>
                    </div>
                    <div class="location--subitem">
                        <h3>Hotel Bergischer Hof Königswinter</h3>
                        <p><a href="https://www.bergischer-hof-koenigswinter.de" target="_blank">Website</a></p>
                        <p>
                            Drachenfelsstraße 33,<br/>
                            53639 Königswinter
                        </p>
                    </div>
                </section>
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.locations
{
    display:grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    justify-content: center;
    gap:1rem;
    padding:1rem;
    text-align: center;
}
.location--subitem
{
    margin: 0 0 1rem;
    background: #FFF;
    padding: 1rem;
    border-radius: 0.75rem;
    border: 1px solid #DDD;
    box-shadow: 0.125rem 0.125rem 0.125rem 0 rgba(50, 50, 50, 0.1);
}
.location--subitem:last-of-type
{
    margin-bottom:0;
}
.location--subitem h3
{
    font-size:1.125rem;
    margin:0 0 0.5rem;
}
.location--subitem.subitem__main
{
    border-width:2px;
    border-color:var(--rust);
}
.location--subitem.subitem__main h3
{
    color:var(--rust);
}
.location--subitem p
{
    margin:0 0 0.5rem;
}
.location--subitem p:last-child
{
    margin-bottom: 0;
}
</style>