<template>
    <div class="page-content">
        <div class="container container__wide">
            <h1 class="page--title">The Day</h1>
            <p class="page--subtitle">How it went down.</p>
            <p class="page--text">We have a couple great photographers for the day who will take pictures for us. We'll share these with you too, so we ask that you put your smartphone away and enjoy our unplugged ceremony.</p>
            <ul class="timeline">
                <li 
                    class="timeline--item"
                    v-for="item, index in items"
                    :key="index">

                    <div class="item--top">
                        <img :src="item.image" class="item--icon" />
                        <span class="item--time">{{ item.time }}</span>
                    </div>
                    <div class="item--bottom">
                        <h2 v-html="item.title" class="item--title"></h2>
                        <p v-html="item.text" class="item--text"></p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            items: [
                {
                    image: require('@/assets/img/icons/rings.png'),
                    time: `14:45`,
                    title: `The<br/>Ceremony`,
                    text: `At Schloss Drachenburg in Koenigswinter. Yes, that means "Castle Dragon Castle."`
                },
                {
                    image: require('@/assets/img/icons/champagne.png'),
                    time: `15:45`,
                    title: `The<br/>Reception`,
                    text: `We'll pop some bubbly on the terrace if the weather lets us. Bistro inside otherwise.`
                },
                {
                    image: require('@/assets/img/icons/wedding-location.png'),
                    time: `17:30`,
                    title: `The<br/>Shuttlebus<br />`,
                    text: `We'll provide a shuttlebus from Königswinter to the pub. More detail to follow on the day.`
                },
                {
                    image: require('@/assets/img/icons/cutlery.png'),
                    time: `18:30`,
                    title: `The<br/>Dinner`,
                    text: `Eat and drink your hearts out upstairs at The Fiddlers Pub in Endenich, Bonn.`
                },
                {
                    image: require('@/assets/img/icons/doves.png'),
                    time: `20:00(...ish)`,
                    title: `The<br/>First Dance`,
                    text: `Watch us lovingly stumble over each other.`
                },
                {
                    image: require('@/assets/img/icons/cake.png'),
                    time: `21:00`,
                    title: `The<br/>Cake!`,
                    text: `Let's be honest. This is the real reason Jill agreed to this.`
                },
                {
                    image: require('@/assets/img/icons/wedding-location-flipped.png'),
                    time: `02:00`,
                    title: `The<br/>Shuttlebus II`,
                    text: `So you don't drunkenly wander around Bonn back to the hotel.`
                }
            ]
        }
    }
}
</script>

<style scoped>
.timeline
{
    list-style:none;
    position:relative;
    display:flex;
    flex-direction:column;

    width:100%;
    max-width:30rem;
    padding:0;
    margin:2rem auto 0;
    column-gap:2rem;
    row-gap:4rem;

    --top-size:6rem;
    --gap:4rem;
}
.timeline::after
{
    content:"";
    display:block;
    position:absolute;
    background:var(--brown);
    
    left:calc(var(--top-size) + var(--gap) / 2);
    top:0;
    height:100%;
    width:2px;
    transform:translateX(-50%);
    border-radius:var(--line-border-radius);
}
.timeline--item
{
    flex:1;
    position:relative;
    display:flex;
    flex-direction: row;
    gap:var(--gap);
}
.timeline--item::before,
.timeline--item::after
{
    content:"";
    display:block;
    position:absolute;
    top:50%;
    left:calc(var(--top-size) + var(--gap) / 2);
    border-radius: var(--line-border-radius);
    background:var(--brown);
    transform:translate(-50%, -50%);
}
.timeline--item::before
{
    height:4px;
    width:4px;
}
.timeline--item::after
{
    height:2px;
    width:calc(var(--gap) / 2);
}
.item--top,
.item--bottom
{
    display:flex;
    flex-direction: column;
}
.item--top
{
    flex:none;
    width:var(--top-size);
    height:var(--top-size);
    align-items:center;
    justify-content: center;
}
.item--bottom
{
    flex:1 1 auto;
}
.item--icon
{
    flex:1 1 auto;
    min-height:0;
    max-width:100%;
}
.item--time
{
    letter-spacing:1px;
}
.item--title
{
    text-transform: uppercase;
    letter-spacing:1px;
    font-size:1rem;
    margin:0 0 1em;
    padding:0;
    font-weight:normal;
}
.item--text
{
    font-weight:lighter;
    font-size:0.75rem;
    line-height:1.5;
    margin:0;
}
@media screen and (min-width:998px)
{
    .timeline
    {
        flex-direction: row;
        justify-content: center;
        max-width:100%;
    }
    .timeline::after
    {
        top:calc(var(--top-size) + var(--gap) / 2);
        left:0;
        height:2px;
        width:100%;
        transform:translateY(-50%);
    }
    .timeline--item
    {
        flex-direction:column;
        align-items: center;
    }
    .timeline--item::before,
    .timeline--item::after
    {
        left:50%;
        top:calc(var(--top-size) + var(--gap) / 2);
    }
    .timeline--item::after
    {
        width:2px;
        height:calc(var(--gap) / 2);
        transform: translate(-50%, -50%);
        background:var(--brown);
    }
    .item--bottom
    {
        text-align:center;
        align-items:center;
    }
}
</style>