<template>
    <header id="site-header">
        <div class="header--body">
            <div
                class="toggle--wrapper">

                <a
                    role="button" 
                    @click="toggleNav"
                    class="header--nav-toggle">

                    <div 
                        class="bars"
                        :class="{ open: navOpen }">

                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                        
                    </div>

                    <span
                        class="toggle--route-name">
                        {{ $route.name }}
                    </span>

                    <div 
                        class="toggle--hint"
                        v-if="showHint">

                        <img 
                            class="hint--image"
                            :src="require('@/assets/img/arrow.svg')"
                            alt="" />

                        <span 
                            class="hint--text">
                        
                            Click here<br/>for more info
                        </span>
                    </div>
                </a>
                
            </div>
            <h1 class="header--site-title">The Costinescu Wedding</h1>
        </div>

        <ul class="header--nav" :class="{ open: navOpen }">
            <li
                v-for="link, index in links"
                :key="index">
                <router-link 
                    @click.native="closeNav" 
                    :to="link.to">{{ link.text }}</router-link>
            </li>
            <li class="logout">
                <a @click="logOut">Logout</a>
            </li>
        </ul>
        <div class="header--nav-overlay" @click="closeNav" :class="{ open: navOpen }"></div>
    </header>
</template>

<script>
import { logOut } from "@/authHandler.js";

export default {
    data() {
        return {
            navOpen: false,
            showHint: true,
            links: [
                {
                    text:"The Gallery",
                    to:"/gallery"
                },
                {
                    text:"The Day",
                    to:"/day"
                },
                {
                    text:"The Story",
                    to:"/story"
                },
                {
                    text:"The Location",
                    to:"/location"
                },
                {
                    text:"The People",
                    to:"/people"
                }
            ]
        };
    },
    created() {
        document.body.classList.add("nav-body");

        document.addEventListener("keyup", (e) => {
            if(e.key === "Escape") {
                this.closeNav();
            }
        })
    },
    destroyed() {
        document.body.classList.remove("nav-body");
    },
    methods: {
        logOut() {
            logOut();
            this.$router.push("/login");
        },
        toggleNav() {
            this.showHint = false;
            this.navOpen = !this.navOpen;
        },
        closeNav() {
            this.navOpen = false;
        }
    }
};
</script>

<style>
.nav-body
{
    padding:3.5rem 0 0;
}
</style>

<style scoped>
#site-header 
{
    --header-height: 3.5rem;
    --nav-transition-time: 300ms;

    position: fixed;
    top: 0;
    left: 0;

    display:flex;
    align-items: center;

    background: #ffffff;
    border-bottom:1px solid #E6E6E6;
    
    width: 100%;
    height:var(--header-height);
    padding:0 1rem;

    z-index: 9999;
}
.header--body
{
    width:100%;
    display:grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
}
.header--site-title
{
    text-align: center;
    margin:0;
    padding:0;
    font-size:1rem;
    font-family:'Fredericka the Great', serif;
    letter-spacing: 1px;
    font-weight:normal;
}
.header--nav 
{
    list-style: none;
    position: fixed;
    padding: 2rem;
    margin: 0;
    left: 0;
    top:var(--header-height);
    bottom:0;
    transform: translateX(-100%);
    transition: transform var(--nav-transition-time) ease;
    background: #fff;

    display: flex;
    flex-direction: column;
    gap:1em;

    width:100%;
    z-index:2;

    font-size:1.125rem;
}
@media screen and (min-width:768px) 
{
    .header--nav
    {
        width:max-content;
        min-width:max(20vw, 20rem);
    }
}
.header--nav a
{
    text-decoration: none;
    font-weight: bold;
    color:var(--superdark-green);
}
.header--nav.open 
{
    transform: translateX(0%);
}
.header--nav-toggle
{
    display:inline-flex;
    align-items: center;
    line-height:1;
    font-weight:bold;
    cursor: pointer;
    gap:1rem;
    position:relative;
}
.toggle--hint
{
    position:absolute;
    bottom:0.5rem;
    right:-0.25rem;
    transform:translate(100%, 100%);
    display:block;
    width:clamp(2rem, 4vw, 4rem);
}
.hint--image
{
    display:block;
}
.hint--text
{
    width:max-content;
    display:block;
    text-align:center;
    font-weight:normal;
    font-size:0.75rem;
    position:absolute;
    bottom:0;
    right:0;
    transform: translate(100%, 100%);
}
.toggle--route-name
{
    display:none;
}
.bars
{
    display:flex;
    position: relative;
    height:0.875rem;
    width:1.125rem;
}
.bar
{
    display:block;
    background:var(--brown);
    height:2px;
    width:1.125rem;
    left:0;
    position:absolute;
    border-radius:var(--line-border-radius);

    transition-duration:var(--nav-transition-time);
    transition-property: all;
    transition-timing-function: ease-in-out;
}
.bar:nth-child(1) 
{
    top:0;
}
.bar:nth-child(2) 
{
    top:50%;
    transform:translateY(-50%);
}
.bar:nth-child(3) 
{
    bottom:0;
}
.bars.open .bar:nth-child(1)
{
    top:50%;
    transform:translateY(-50%) rotate(45deg);
}
.bars.open .bar:nth-child(2) 
{
    opacity:0;
    transform: rotate(90deg);
}
.bars.open .bar:nth-child(3) 
{
    bottom:50%;
    transform:translateY(50%) rotate(-45deg);
}
.header--nav-overlay
{
    z-index:1;
    background:rgba(0,0,0,0.3);
    position:fixed;
    top:var(--header-height);
    left:0;
    right:0;
    bottom:0;

    transition:opacity 400ms ease-in-out;
    opacity:0;
    pointer-events: none;
}
.header--nav-overlay.open
{
    opacity:1;
    pointer-events:initial;
}
.logout
{
    margin-top:auto;
}
.logout a
{
    cursor:pointer;
}
@media screen and (min-width:768px)
{
    .toggle--route-name
    {
        display:initial;
    }
}
</style>