<template>
    <div class="page-content">
        <div class="container container__narrow">
            <h1 class="page--title">The Gallery</h1>
            <p class="page--subtitle">We've finally got some pictures to share.</p>
            
            <basics-section title="" center>
                <a href="https://apps.scrappbook.de/share/rkfi7Sg8q" target="_blank">Click here to check them out</a><br/><br/>
                <strong>Password:</strong> JilAle0322
            </basics-section>
        </div>
    </div>
</template>

<script>
import BasicsSection from '../components/BasicsSection.vue';

export default {
    components: {
        BasicsSection
    }
}
</script>