<template>
    <div class="page-content">
        <h1 class="page--title">The People</h1>
        <p class="page--subtitle">Get to know the wedding party.</p>
        
        <div class="container">
            <section class="people">
                <div 
                    class="person"
                    v-for="person, index in people"
                    :key="index"
                    :style="{ transform: getRandomTransformation() }">

                    <img 
                        class="person--picture" 
                        :src="person.picture" 
                        alt="" />

                    <h2 class="person--name">{{ person.name }}</h2>
                    <p class="person--description">{{ person.description }}</p>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            people: [
                {
                    name: "Yvonne Märzhäuser",
                    description: "Mother of the Bride",
                    picture: require('@/assets/img/people/yvonne-maerzhaeuser.jpg')
                },
                {
                    name: "Carmen Costinescu",
                    description: "Mother of the Groom",
                    picture: require('@/assets/img/people/carmen-costinescu.jpg')
                },
                {
                    name: "Gernot Märzhäuser",
                    description: "Father of the Bride",
                    picture: require('@/assets/img/people/gernot-maerzhaeuser.jpg')
                },
                {
                    name: "Vlad Costinescu",
                    description: "Father of the Groom",
                    picture: require('@/assets/img/people/vlad-costinescu.jpg')
                },
                {
                    name: "Andie Costinescu",
                    description: "Sister of the Groom",
                    picture: require('@/assets/img/people/andie-costinescu.jpg')
                },
                {
                    name: "Gioia Märzäuser",
                    description: "Sister of the Bride",
                    picture: require('@/assets/img/people/gioia-maerzhaeuser.jpg')
                },
                {
                    name: "Soley Märzhäuser",
                    description: "Sister of the Bride",
                    picture: require('@/assets/img/people/soley-maerzhaeuser.jpg')
                },
                {
                    name: "Sandra Lamneck",
                    description: "Maid of Honor",
                    picture: require('@/assets/img/people/sandra-lamneck.jpg')
                },
                {
                    name: "Brian Groenke",
                    description: "Best Man",
                    picture: require('@/assets/img/people/brian-groenke.jpg')
                },
                {
                    name: "Ian Briggs",
                    description: "Groomsman",
                    picture: require('@/assets/img/people/ian-briggs.jpg')
                },
                {
                    name: "Maiwand Bassam",
                    description: "Groomsman",
                    picture: require('@/assets/img/people/maiwand-bassam.jpg')
                },
                {
                    name: "August & Calcifer",
                    description: "The cats",
                    picture: require('@/assets/img/people/august-calcifer.jpg')
                }
            ]
        }
    },
    methods: {
        getRandomTransformation() {
            const maxMovement = 5; //px in each direction
            const maxRotation = 2; //deg in each direction

            const translateX = Math.round(Math.random() * maxMovement * 2 - maxMovement);
            const translateY = Math.round(Math.random() * maxMovement * 2 - maxMovement);

            const rotate = Math.random() * maxRotation * 2 - maxRotation;
            
            return `
                translateX(${translateX}px) 
                translateY(${translateY}px) 
                rotate(${rotate.toFixed(2)}deg)
            `;
        }
    }
}
</script>

<style scoped>
.people
{
    display:grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    justify-content: center;
    gap:3rem 1.5rem;
    padding:2rem 0;
    text-align: center;
}

.person
{
    display:flex;
    flex-direction: column;
    align-items:center;
    text-align:center;

    background: #FFF;
    border-radius:0.75rem;
    border:1px solid #ddd;
    padding:1rem;

    box-shadow: 0.125rem 0.125rem 0.125rem 0 rgba(50, 50, 50, 0.1);
}
.person--picture
{
    display:block;
    aspect-ratio: 1 / 1;
    width:100%;
    /* max-width:15rem; */
    border-radius:0.75rem;
    margin:0 0 1rem;
    object-fit: cover;
    object-position: center top;
}
.person--name
{
    margin:0;
    padding:0;
    font-size:1.125rem;
    font-family: 'Fredericka the Great', serif;
    font-weight:normal;
    
}
.person--description
{
    margin:0;
    padding:0;
    color:var(--rust);
    font-size:0.875rem;
}
</style>