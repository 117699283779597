import sha256 from 'crypto-js/sha256';

const HASH = "a2470c9d137c1c5d3567d1180a64cb43a9269c4d6f1ff13ac8cdbaf6fc5df3b7";

const LS_KEY = "loggedIn";

export function isLoggedIn() {
    return sha256(localStorage.getItem(LS_KEY)).toString() === HASH;
}

export function tryLogIn(password) {
    console.log(sha256(password).toString());
    if(sha256(password).toString() !== HASH) {
        return false;
    }

    localStorage.setItem(LS_KEY, password);
    return true;
}

export function logOut() {
    localStorage.removeItem(LS_KEY);
}