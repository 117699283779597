<template src="./App.html"></template>

<script>
import SiteHeader from '@/components/SiteHeader';
import { isLoggedIn } from './authHandler.js';

export default {
  name: 'App',
  components: {
    SiteHeader
  },
  methods: {
    isLoggedIn
  }
}

export const defaultPage = "/gallery";
</script>

<style src="./App.css"></style>
